import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

export type ErrorPopupState = {
  errorMessage?: string;
  setErrorMessage: (message?: string) => void;
};

export const ErrorPopupContext = React.createContext<ErrorPopupState>({
  setErrorMessage: () => {
    /* Do nothing */
  },
});

export const ErrorPopup = (): JSX.Element => {
  return (
    <ErrorPopupContext.Consumer>
      {(value) => (
        <Modal
          isOpen={value.errorMessage !== undefined}
          onClose={() => {
            value.setErrorMessage(undefined);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Sorry, an error occurred</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{value.errorMessage}</ModalBody>

            <ModalFooter>
              <Button
                colorScheme="pink"
                mr={3}
                onClick={(e) => {
                  value.setErrorMessage(undefined);
                  e.preventDefault();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ErrorPopupContext.Consumer>
  );
};
