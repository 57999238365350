import { UserProvider } from '@auth0/nextjs-auth0';
import { NextSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React, { useState } from 'react';
import 'react-day-picker/dist/style.css';
import { DehydratedState, QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';

import { ErrorPopupContext } from '../components/ErrorPopup';
import '../global_styles.css';

const siteTitle = 'Support RTRFM';
const seoSiteTitle = 'Support RTRFM during Radiothon 2024';
const siteDescription = `RTRFM is your independent, non-profit community radio station`;

function MyApp({
  Component,
  pageProps,
  router,
}: AppProps<{ dehydratedState: DehydratedState }>): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
      </Head>

      <NextSeo
        title={siteTitle}
        description={siteDescription}
        openGraph={{
          type: 'website',
          url: process.env.NEXT_PUBLIC_BASE_URL,
          title: seoSiteTitle,
          description: siteDescription,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_BASE_URL}/2024_radiothon_1200x630.jpg`,
              width: 1200,
              height: 630,
              alt: 'Radiothon 2024',
            },
          ],
          site_name: 'support.rtrfm.com.au',
        }}
        twitter={{
          handle: '@RTRFM',
          cardType: 'summary_large_image',
        }}
      />

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <UserProvider>
            <ErrorPopupContext.Provider value={{ errorMessage, setErrorMessage }}>
              <Component {...pageProps} key={router.asPath} />
            </ErrorPopupContext.Provider>
          </UserProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
